import { useRecoilState } from 'recoil';
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { CountrySettings } from "../../Atoms/CountrySettings";
import AuLanding from "./CountrySpecific/AUS/AuLanding";
import DefaultLanding from "./CountrySpecific/DefaultLanding";
import DefaultComplete from "../Complete/CountrySpecific/DefaultComplete";
import AuComplete from "../Complete/CountrySpecific/AUS/AuComplete";
import JpLanding from './CountrySpecific/JP/JpLanding';
import JpComplete from '../Complete/CountrySpecific/JP/JpComplete';
import { IStoreResponse } from '../../Models/IStoreResponse';
import SeLanding from './CountrySpecific/SE/SeLanding';

export default function Landing() {
    const [countrySettings, setCountrySettings] = useRecoilState(CountrySettings)
    const [searchParams] = useSearchParams();

    let landingPage: JSX.Element = <DefaultLanding />;
    let completePage: JSX.Element = <DefaultComplete />;
    let phoneCode = "+45";
    const REACT_APP_BACKEND_STORENAME_ENDPOINT = process.env.REACT_APP_BACKEND_STORENAME_ENDPOINT;

    const getStore = async (): Promise<string | null> => {
        const storeKey = searchParams.get('store');

        const storeData:IStoreResponse = await getStoreData(storeKey);
          
        setCountrySettings({...countrySettings, storeKey: storeData.storeKey, storeName: storeData.storeName})

        return storeKey;
    }

    const getStoreData = async (storeKey: string | null): Promise<IStoreResponse> => {

        if (storeKey === null || storeKey === undefined){
          return {storeName: null, storeKey: "0000"}
        }

        const endpoint = `${REACT_APP_BACKEND_STORENAME_ENDPOINT}&StoreKey=${storeKey}`;
        
        const response = await fetch(endpoint);
      
        if (!response.ok) {
            return {storeName: null, storeKey: "0000"}
        }
      
        const storeData: IStoreResponse = await response.json();
        return storeData;
      }

    const getCountry = (): string => {
      console.log(`country: ${searchParams.get('country')}`); 
      const country = searchParams.get('country');

      if (country === 'dk'){
          setCountrySettings({...countrySettings, country: "dk"})
          return "dk";
      }
      else if (country === 'se'){
          setCountrySettings({...countrySettings, country: "se"})
          return "se";
      }
      else if (country === 'gb'){
          setCountrySettings({...countrySettings, country: "gb"})
          return "gb";
      } 
      else if (country === 'au'){
          setCountrySettings({...countrySettings, country: "aus"})
          return "au";
      } 
      else if (country === 'jp'){
        setCountrySettings({...countrySettings, country: "jp"})
        return "jp";
    } 
      else {
          setCountrySettings({...countrySettings, country: "dk"})
          return "dk";
      }
  }
  
  const getLanguage = () => {
      const country = searchParams.get('country');

        if (country !== null && country !== undefined ){
            return country.toUpperCase();
        } else {
            return "DK";
        }
  }
    const SetCountrySettings = async (countryCode: string) => {
        try {
            // var storeKey = await getStore();
            const storeKey = searchParams.get('store');
            const storeData:IStoreResponse = await getStoreData(storeKey);

            var lang = getLanguage()
            var langOpt = null;
            switch (countryCode) {
                case "au":
                    landingPage = <AuLanding />
                    completePage = <AuComplete />
                    phoneCode='+61'
                    break;
                case "dk":
                    langOpt = ["GB", "DK"]
                    phoneCode='+45'
                    break;
                case "se":
                    langOpt = ["GB", "SE"]
                    landingPage = <SeLanding />
                    phoneCode='+46'
                    break;
                case "jp":
                    langOpt = ["GB", "JP"]
                    landingPage = <JpLanding />
                    completePage = <JpComplete />
                    phoneCode='+81'
                    break;
                default:
                    break;
            }
            setCountrySettings({completePage: completePage , landingPage: landingPage, phoneCode: phoneCode, language: lang, languageOptions: langOpt, country: countryCode, storeKey: storeData.storeKey, storeName: storeData.storeName})
        } catch (err) {

        }
    };
  
  useEffect(() => {
    console.log(`Environment: ${process.env.NODE_ENV}` );
    var countryCode = getCountry();
    SetCountrySettings(countryCode);
    
    return () => {
      
    }
  }, [])

    return (
        countrySettings.landingPage
    )
};
