import { IComplete } from "../../Models/IComplete";
import axios from "axios";
import { ISignup } from "../../Models/ISignup";
import { SetterOrUpdater } from 'recoil';
import { ICountrySettings } from "../../Models/ICountrySettings";


export const signalStayAlive = (
    endpoint: string | undefined,
    setLoading: SetterOrUpdater<boolean>
  ): void => {
    if (!endpoint) {
      console.log("Endpoint is not set");
      setLoading(false);
      return;
    }
  
    axios
      .get<IComplete>(endpoint, {
        params: { isStayAlive: true },
      })
      .then(() => {
        console.log('Pinged Azure Function');
      })
      .catch((error) => {
        console.log(`Pinged Azure Function, error: ${error}`);
      });
  };
  
  export const postMemberToCrm = (
    params: ISignup,
    endpoint: string | undefined,
    countrySettings: ICountrySettings,
    setLoading: SetterOrUpdater<boolean>,
    setQrcode: SetterOrUpdater<IComplete | null>,
    setShowForm: SetterOrUpdater<boolean>,
    setErrorMessage: SetterOrUpdater<string>,
    t: (key: string) => string
  ): void => {
    console.log(`Environment: ${process.env.NODE_ENV}`);
    if (!endpoint) {
      console.log("Endpoint is not set");
      setLoading(false);
      return;
    }
  
    axios
      .get<IComplete>(endpoint, {
        params: {
          email: params.email,
          phone: params.phone,
          phoneCode: params.phoneCode,
          firstname: params.firstName,
          lastname: params.lastName,
          country: countrySettings.country,
          store: countrySettings.storeKey,
          marketing: params.marketing,
          birthdate: params.birthdate,
        },
      })
      .then((response) => {
        const { data } = response;
        setQrcode(data as IComplete);
        setShowForm(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(t('error_message_key')); // Replace with actual translation key
        console.error(error);
      });
  };