import styles from "./SeLanding.module.scss";
import "./SeLanding.module.scss";
import logo from '../../../../assets/logo/GJ_MASTERBRAND_LOGO_BLACK_180x93_px.svg';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { useRecoilState } from 'recoil';
import { SignUpForm } from '../../../../Atoms/SignUpForm';
import { ISignup } from '../../../../Models/ISignup';
import { QrcodeAtom } from '../../../../Atoms/QrcodeAtom';
import { ShowForm } from '../../../../Atoms/ShowForm';
import { Loading } from "../../../../Atoms/Loading";
import Consent from "../../../../Components/Text/Consent";
import { ErrorMessage } from "../../../../Atoms/ErrorMessage";
import { DisableButton } from "../../../../Atoms/DisableButton";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { CountrySettings } from "../../../../Atoms/CountrySettings";
import ReactFlagsSelect from "react-flags-select";
import { postMemberToCrm, signalStayAlive } from "../../../../lib/Backend/Backend";


export default function SeLanding() {
    const [form] = Form.useForm();
    const [signUpForm, setSignUpForm] = useRecoilState(SignUpForm)
    const [showForm, setShowForm] = useRecoilState(ShowForm)
    const [qrcode, setQrcode] = useRecoilState(QrcodeAtom)
    const [loading, setLoading] = useRecoilState(Loading)
    const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessage)
    const [disableButton, setDisableButton] = useRecoilState(DisableButton)
    const [countrySettings, setCountrySettings] = useRecoilState(CountrySettings)
    const { t, i18n } = useTranslation();
    const  endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    useEffect(() => {
        signalStayAlive(endpoint, setLoading);
        i18n.changeLanguage(countrySettings.language);
        let store = countrySettings.storeName ?? t("UnknownStore");
        setCountrySettings({...countrySettings, storeName: store})

        return () => {
        
      }
    }, [])

    function onSubmit(values: ISignup) {
        setLoading(true);
        setSignUpForm(values);
        postMemberToCrm(values, endpoint, countrySettings, setLoading, setQrcode, setShowForm, setErrorMessage,t);
    }
    function onFormChange(changedValues: ISignup, allValues: ISignup){
        if (!nullOrEmpty(allValues.email) && !nullOrEmpty(allValues.firstName) && !nullOrEmpty(allValues.lastName) && allValues.marketing === true){
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }

    function onSelect(country: string) {

        console.log("language set to: " + country)
        setCountrySettings({...countrySettings, language: country})
        i18n.changeLanguage(country);
    }
    function nullOrEmpty(str: string | null){
        if (str === null || str?.trim() === "") {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className={styles.center}>
        <HelmetProvider>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
                <title>Georg Jensen</title>
            </Helmet>
        </HelmetProvider>
            <header style={{width: "100%", height: "0px"}}>
                {countrySettings.languageOptions != null ? 
                <div style={{float: "right", margin: "5px"}}>
                    <ReactFlagsSelect
                        selected={countrySettings.language.toUpperCase()}
                        onSelect={onSelect}
                        countries={countrySettings.languageOptions}
                        showSelectedLabel={false}
                        showOptionLabel={false}
                        />
                </div>
                : <div/>
                }
            </header>
            <img loading="lazy" src={logo} alt="My Happy SVG " className={styles.logo} />

            {
                showForm ?

                    <Form
                        className={styles.form}
                        initialValues={{ remember: true }}
                        onFinish={onSubmit}
                        onValuesChange={onFormChange}
                        autoComplete="off"
                        form={form}
                    >
                        <div className={styles.AntForm} >
                            <Form.Item name="email" rules={[{ required: true, message: t("InvalidEmail"), type:'email' }]}>
                                <Input placeholder={t('Email')} />
                            </Form.Item>

                            <Form.Item>
                                <div className={styles.phoneWrapper}>
                                    <Form.Item
                                    name="phoneCode"
                                    rules={[{ required: true, message: 'Phone code is required' }]}
                                    noStyle
                                    initialValue={countrySettings.phoneCode}
                                    >
                                    <Input className={styles.phoneCodeInput} disabled />
                                    </Form.Item>

                                    <Form.Item
                                    name="phone"
                                    rules={[{ required: true, message: t("PhoneRequired") }, { max: 20, message: t("PhoneTooLong")}]}
                                    noStyle
                                    >
                                    <Input className={styles.phoneNumberInput} placeholder="Phone number" />
                                    </Form.Item>
                                </div>
                            </Form.Item>

                            <Form.Item name="firstName" rules={[{ required: true, message: '' }]} >
                                <Input placeholder={t("FirstName")}/>
                            </Form.Item>

                            <Form.Item name="lastName"  rules={[{ required: true, message: ""}]}>
                                <Input placeholder={t("LastName")}/>
                            </Form.Item>

                            <Form.Item name="store"  rules={[{ required: false, message: ""} ]}>
                                <Input disabled={true} placeholder={countrySettings.storeName as string}/>
                            </Form.Item>

                            <Form.Item label={<label id='disclaimer'>{t("Birthday") as string}</label>} name="birthdate" >
                                <Input style={{minHeight: "34px"}} type="date"  placeholder="Fødselsdag"/>  
                            </Form.Item>
                            
                            <Form.Item name="marketing" valuePropName="checked"  rules={[
                                {
                                    validator: async (_, checked) => {
                                        if (!checked) {
                                            return Promise.reject(
                                                new Error(""),
                                            );
                                        }
                                    },
                                },
                            
                            ]}>
                            <div className={styles.consent}>
                                <Checkbox style={{marginRight: '10px'}} />
                                <Consent />
                            </div>                           
                            </Form.Item>
                            <Form.Item>
                                <Button disabled={loading || disableButton} type="primary" htmlType="submit" id='button'>
                                    <p id='buttonText'>{t("ButtonText") as string}</p>
                                </Button>
                            </Form.Item>
                            {
                                loading ? 

                                <Spin tip={t("LoadingText") as string} size="large"/> : <div>{errorMessage}</div>
                            }
                        </div>
                    </Form>
                    :
                    countrySettings.completePage
            }
           
        </div>
    )
};
