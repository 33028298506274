import styles from "./AuLanding.module.scss";
import logo from '../../../../assets/logo/GJ_MASTERBRAND_LOGO_BLACK_180x93_px.svg';
import { Button, Checkbox, Form, Input, Select, Spin } from 'antd';
import { useRecoilState } from 'recoil';
import { SignUpForm } from '../../../../Atoms/SignUpForm';
import { ISignup } from '../../../../Models/ISignup';
import { QrcodeAtom } from '../../../../Atoms/QrcodeAtom';
import { ShowForm } from '../../../../Atoms/ShowForm';
import { Loading } from "../../../../Atoms/Loading";
import Consent from "../../../../Components/Text/Consent";
import { ErrorMessage } from "../../../../Atoms/ErrorMessage";
import { DisableButton } from "../../../../Atoms/DisableButton";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { CountrySettings } from "../../../../Atoms/CountrySettings";
import { postMemberToCrm, signalStayAlive } from "../../../../lib/Backend/Backend";


export default function AuLanding() {
    const [form] = Form.useForm();
    const [signUpForm, setSignUpForm] = useRecoilState(SignUpForm)
    const [showForm, setShowForm] = useRecoilState(ShowForm)
    const [qrcode, setQrcode] = useRecoilState(QrcodeAtom)
    const [loading, setLoading] = useRecoilState(Loading)
    const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessage)
    const [disableButton, setDisableButton] = useRecoilState(DisableButton)
    const [countrySettings] = useRecoilState(CountrySettings)
    const { t, i18n } = useTranslation();
    const  endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    const { Option } = Select;
    
    useEffect(() => {
        signalStayAlive(endpoint, setLoading);
        i18n.changeLanguage(countrySettings.language);
    
        return () => {
        
      }
    }, [])

    function onSubmit(values: ISignup) {
        setLoading(true);
        setSignUpForm(values);
        postMemberToCrm(values, endpoint, countrySettings, setLoading, setQrcode, setShowForm, setErrorMessage,t);
    }
    function onFormChange(changedValues: ISignup, allValues: ISignup){
        if (!nullOrEmpty(allValues.email) && !nullOrEmpty(allValues.firstName) && !nullOrEmpty(allValues.lastName) && allValues.marketing === true && allValues.state !== undefined ){
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }

    function nullOrEmpty(str: string | null){
        if (str === null || str?.trim() === "") {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className={styles.center}>
        <HelmetProvider>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
                <title>Georg Jensen</title>
            </Helmet>
        </HelmetProvider>
            <img loading="lazy" src={logo} alt="My Happy SVG " className={styles.logo} />

            {
                showForm ?

                    <Form
                        className={styles.form}
                        initialValues={{ remember: true }}
                        onFinish={onSubmit}
                        onValuesChange={onFormChange}
                        autoComplete="off"
                        form={form}
                    >
                        <div className={styles.AntForm} >
                            <Form.Item name="email" rules={[{ required: true, message: t("InvalidEmail"), type:'email' }]}>
                                <Input placeholder={t('Email')} />
                            </Form.Item>
                            <Form.Item name="firstName" rules={[{ required: true, message: '' }]} >
                                <Input placeholder={t("FirstName")}/>
                            </Form.Item>
                            <Form.Item name="lastName"  rules={[{ required: true, message: ""}]}>
                                <Input placeholder={t("LastName")}/>
                            </Form.Item>
                            <Form.Item name="state" rules={[{ required: true, message: ""}]}>
                                <Select
                                    placeholder="State"
                                >
                                    <Option value="ACT">Australian Capital Territory</Option>
                                    <Option value="NSW">New South Wales</Option>
                                    <Option value="NT">Nothern Territory</Option>
                                    <Option value="QLD">Queensland</Option>
                                    <Option value="SA">South Australia</Option>
                                    <Option value="TAS">Tasmania</Option>
                                    <Option value="VIC">Victoria</Option>
                                    <Option value="WA">Western Australia</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label={<label id='disclaimer'>{t("Birthday") as string}</label>} name="birthdate" >
                                <Input style={{minHeight: "34px"}} type="date"  placeholder="Fødselsdag"/>  
                            </Form.Item>
                            <Form.Item name="marketing" valuePropName="checked"  rules={[
                                {
                                    validator: async (_, checked) => {
                                        if (!checked) {
                                            return Promise.reject(
                                                new Error(""),
                                            );
                                        }
                                    },
                                },
                            
                            ]}>
                            <div className={styles.consent}>
                                <Checkbox defaultChecked={true} style={{marginRight: '10px'}} />
                                <Consent />
                            </div>                           
                            </Form.Item>
                            <Form.Item>
                                <Button disabled={loading || disableButton} type="primary" htmlType="submit" id='button'>
                                    <p id='buttonText'>{t("ButtonText") as string}</p>
                                </Button>
                            </Form.Item>
                            {
                                loading ? 

                                <Spin tip={t("LoadingText") as string} size="large"/> : <div>{errorMessage}</div>
                            }
                        </div>
                    </Form>
                    :
                    countrySettings.completePage
            }
        </div>
    )
};
